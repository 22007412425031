import { Fragment, useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { Badge, Button, Typography } from '@mui/material'
import {
  CalendarToday,
  ErrorRounded,
  Notifications,
  Phone,
  Send,
} from '@mui/icons-material'
import { getDecodedToken } from '@resolvex/resolvex-sdk'
import {
  Notification,
  useGetNotificationsByIdLazyQuery,
} from 'graphql/gen-types'
import { getContext } from 'utils/store'
import moment from 'moment-timezone'
import { useMutation } from '@apollo/client'
import {
  UPDATE_NOTIFICATION_ALL,
  UPDATE_NOTIFICATION_BY_ID,
} from 'graphql/notificationGql'
import useSocket from './utilities/useSocket'
import { URL_ACCOUNT_SERVICE } from 'utils/variables/constants'
import { useSnackbar } from 'notistack'

interface NotificationData {
  message: string
  url: string
}

export const NotificationsBell = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar()
  const { clientName = 'mhagc', id } = getDecodedToken()
  const { navigate } = getContext()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    getNotifications()
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const [updateNotificationById] = useMutation(UPDATE_NOTIFICATION_BY_ID)
  const [updateNotificationAll] = useMutation(UPDATE_NOTIFICATION_ALL)

  const handleMarkAllAsRead = async () => {
    await updateNotificationAll({
      variables: {
        updateNotificationAllArgs: {
          clientName: clientName,
          read: true,
        },
      },
    })

    getNotifications()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleNotificationClick = async (notification: any) => {
    // Mark the notification as read
    // markNotificationAsRead(id) // You need to implement this function
    if (notification.id) {
      await updateNotificationById({
        variables: {
          updateNotificationByIdArgs: {
            userId: id,
            clientName: clientName,
            id: notification.id,
            title: notification.title,
            message: notification.message,
            read: true,
            url: notification.url,
            updatedAt: new Date().toISOString(),
          },
        },
      })
    }

    // Navigate to the specified URL if it exists
    if (notification.url) {
      handleCloseMenu()

      // todo replace with proper caseform refetch on
      // same id different session
      const searchParams = new URLSearchParams(notification.url.split('?')[1])
      const id = searchParams.get('id')
      if (window.location.search.includes(`id=${id}`)) {
        navigate(notification.url)
        window.location.reload()
      } else {
        navigate(notification.url)
      }
    }
  }

  const [, { refetch, data, loading, error }] =
    useGetNotificationsByIdLazyQuery({
      variables: {
        getNotificationsByIdArgs: {
          clientName: clientName,
          userId: id,
        },
      },
      fetchPolicy: 'network-only',
    })

  const getNotifications = () => {
    if (clientName && id) {
      refetch({
        getNotificationsByIdArgs: {
          clientName: clientName,
          userId: id,
        },
      })
    }
  }

  const notificationsData: Notification[] = useMemo(() => {
    if (!loading && !error && data?.getNotificationsById) {
      return data.getNotificationsById
    }

    return []
  }, [data, loading, error])

  const unreadNotificationsCount = notificationsData.filter(
    (notification) => !notification.read
  ).length

  // Notification Code
  const socket = useSocket(`${URL_ACCOUNT_SERVICE}/notifications`)

  useEffect(() => {
    getNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!socket || !id) {
      return
    }

    socket.emit('register', id)

    socket.on('user-notification', (data: NotificationData) => {
      const action = () => (
        <>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Send />}
            onClick={() => handleNotificationClick(data)}
          >
            Open
          </Button>
        </>
      )

      enqueueSnackbar(
        <>
          <Typography variant="overline">{data.message} &nbsp;</Typography>
        </>,
        {
          action,
          variant: 'info',
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
          hideIconVariant: true,
          autoHideDuration: 5000,
        }
      )

      refetch({
        getNotificationsByIdArgs: {
          clientName: clientName,
          userId: id,
        },
      })
    })

    socket.on('error', () => {
      enqueueSnackbar(
        <Typography variant="overline" color="error.contrastText">
          Something went wrong with notification service
        </Typography>,
        {
          variant: 'error',
        }
      )
      console.error('Something went wrong with the socket')
    })

    return () => {
      socket.off('user-notification')
      socket.off('connected')
      socket.off('error')
    }
  }, [socket]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Notifications">
          <IconButton color="inherit" onClick={handleClick}>
            <Badge badgeContent={unreadNotificationsCount} color="primary">
              <Notifications color="action" />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleMarkAllAsRead}>
          <Typography
            variant="h6"
            color="primary.main"
            noWrap={false}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            Mark all as read
          </Typography>
        </MenuItem>
        <Box sx={{ maxHeight: 400, maxWidth: '480px', overflow: 'auto' }}>
          {notificationsData.length > 0 ? (
            notificationsData.map((notification, index) => (
              <Fragment key={index}>
                <MenuItem
                  onClick={() => handleNotificationClick(notification)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    alignItems: 'start',
                    gap: 1,
                    py: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      alignItems: 'start',
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={notification.read ? 'info.main' : 'info.dark'}
                      noWrap={false}
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {notification.title}
                    </Typography>
                    {!notification.read && (
                      <ErrorRounded style={{ color: '#4287f5' }} />
                    )}
                  </Box>

                  <Typography
                    variant="subtitle1"
                    color={notification.read ? 'info.main' : 'info.dark'}
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                  >
                    <Phone color="info" fontSize="small" />
                    {notification.message}
                  </Typography>

                  <Typography
                    variant="caption"
                    color={notification.read ? 'info.main' : 'info.dark'}
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                  >
                    <CalendarToday color="info" fontSize="small" />
                    {notification.createdAt
                      ? `Delivered at ${moment(notification.createdAt)
                          .tz('America/New_York')
                          .format('MMMM Do YYYY, h:mm:ss a')}`
                      : 'Not delivered yet'}
                  </Typography>
                </MenuItem>
                <Divider />
              </Fragment>
            ))
          ) : (
            <MenuItem>
              <div className="notification">
                <div className="notification-content">
                  <p className="notification-title">
                    No notifications in the last 7 days
                  </p>
                </div>
              </div>
            </MenuItem>
          )}
        </Box>
      </Menu>
    </Fragment>
  )
}
